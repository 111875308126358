import React, { Component } from "react";
import { Gaming } from "./Gaming";
import { Partners } from "./Partners";
import { Technology } from "./Technology";
import { WhatWeDo } from "./WhatWeDo";
import { SectionHeader } from "../../components/header/SectionHeader";
import {
  home,
  mojaGroupCut,
  mojaGroup,
  map,
  mojaGroupAll,
} from "../../components/library/Library";
import { CurrentSubsidiaris } from "./CurrentSubsidiaris";
import { SectionHeading } from "../../components/sections/SectionHeading";
import { ImageTextRow } from "../../components/sections/ImageTextRow";
import { Background } from "../../components/sections/Background";
import { Loader } from "../../components/sections/Loader";
import { VectorMap } from "react-jvectormap";

export default class HomePage extends Component {
  state = {
    currentRegion: [
      { title: "Uganda", code: "UG" },
      { title: "DRC", code: "CD" },
      { title: "Ghana", code: "GH" },
      { title: "Kenya", code: "KE" },
      { title: "Ivory Coast", code: "CI" },
      { title: "Senegal", code: "SN" },
      { title: "Tanzania", code: "TZ" },
    ],
    countriesCode: ["SN", "CI", "GH", "CD", "UG", "KE","TZ"],
    regionHovered: [],
  };
  handleClick = async (e, countryCode) => {
    // console.log(e);
    // if (this.state.countriesCode.indexOf(countryCode) != -1 && this.state.regionHovered.indexOf(countryCode)==-1)
    //  {
    //    let temp=this.state.regionHovered;
    //    temp.push(countryCode);
    //    await this.setState({ regionHovered: temp });
    //  }
  };

  render() {
    const { currentRegion, regionHovered, countriesCode } = this.state;

    let scope = "Scope";
    let scopeBody = (
      <>
        {/* <p>
          Our proven gaming technology enables us to work as a trusted National
          Lottery partner with Governments across Africa.
        </p>
        <p>
          On an exclusive and non-exclusive basis, we offer games of chance,
          casinos, sports betting and horse racing through market-leading
          mobile, online and digital platforms.
        </p>*/}
        <p>
          &#x2752; <strong>Current Countries:</strong>
        </p>
        {currentRegion.map((e, i) => {
          return (
            <p
              key={i}
              style={{
                margin: 0,
                fontFamily:
                  regionHovered.indexOf(e.code) != -1
                    ? "OpenSans-s"
                    : "OpenSans-l",
              }}
            >
              {e.title}
            </p>
          );
        })}
      </>
    );
    const mapData = {
      SN: 0,
      CI: 20000,
      GH: 0,
      CD: 0,
      UG: 0,
      KE: 0,
    };

    return (
      <>
        <Loader />
        <SectionHeader
          fTitle="MOJA"
          sTitle="GROUP"
          desc="Africa’s Digitalisation Expert"
          img={home}
        />

        <Background number={1} />

        <WhatWeDo />
        <Background number={2} vw={"70vw"} />

        <div className="mb-5 " id="scope">
          <div className="container">
            <SectionHeading
              title={"SCOPE"}
              body={
                "Africa is often referred as “Tomorrow’s Continent” we therefore strive to apply proven technologies today to accelerate Africa’s growth and help it achieve its potential through digitalisation!"
              }
            />
          </div>
          {/* <ImageTextRow
            textLeft={false}
            // textTitle={scope}
            textBody={scopeBody}
            img={map}
          /> */}
          <section id="team">
            <div className="container">
              <div className="row ">
                <div
                  className="col-md-6 mt-md-0  d-flex"
                  style={{ justifyContent: "center" }}
                >
                  <div
                    className=" d-block d-sm-none"
                    style={{ width: "100%", height: "auto" }}
                  >
                    <img
                      className="rightImage"
                      src={map}
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                  <div
                    className="d-none d-sm-block"
                    style={{ width: "100%", height: "auto" }}
                  >
                    <VectorMap
                      map={"africa_mill"}
                      backgroundColor="transparent" //change it to ocean blue: #0077be
                      zoomOnScroll={false}
                      containerStyle={{
                        width: "100%",
                        height: "520px",
                      }}
                      // onRegionOver={this.handleClick}
                      // onRegionOut={()=>this.setState({ regionHovered: null })}
                      onRegionClick={(e, c) => this.handleClick(e, c)} //gets the country code
                      containerClassName="map"
                      regionStyle={{
                        regionLabelStyle: {
                          initial: {
                            "font-family": "Verdana",
                            "font-size": "12",
                            "font-weight": "bold",
                            cursor: "default",
                            fill: "red",
                          },
                          hover: {
                            cursor: "pointer",
                          },
                        },
                        initial: {
                          fill: "#258bc8",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0,
                        },
                        hover: {
                          "fill-opacity": 0.8,
                          cursor: "pointer",
                        },
                        selected: {
                          fill: "#050c78", //color for the clicked country
                        },
                        selectedHover: { fill: "#1e2485e0" },
                      }}
                      // regionsSelectable={true}
                      selectedRegions={countriesCode}
                      // series={{
                      //   regions: [
                      //     {
                      //       values: mapData, //this is your data
                      //       scale: ["#050c78", "#040f92"], //your color game's here
                      //       normalizeFunction: "polynomial",
                      //     },
                      //   ],
                      // }}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-md-0 ">
                  <br />
                  <div className="textExtra">{scopeBody}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Background number={3} />

        <CurrentSubsidiaris />
        <Background number={2} />
        <Background number={3} />
        <div id="technology">
          <Technology />
        </div>

        <Background number={1} vw={"70vw"} />
        {/* <div className="section-bg-video d-none d-md-block" data-overlay-color="3">
          <div className="container-video text-center text-white">
            <div className="container h-100 d-flex align-items-center justify-content-center">
              <h1>
                <b>MOJA</b> GROUP
              </h1>
            </div>
          </div>
          <div className="bg-overlay transparent-dark bg-black"></div>
          <div className="videobox"> */}
        {/* <video autoPlay loop muted poster="video/video.jpg">
                    <source src="video/video.mp4" type="video/mp4"/>
                </video> */}
        {/* <img src={mojaGroupCut} className="h-50 w-100" />
          </div>
        </div> */}
        <section
          className="parallax"
          data-overlay-color="6"
          // data-background={mojaGroupAll}
          style={{
            backgroundImage: `url(${mojaGroupAll})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-5">
                <h1>
                  <b>MOJA</b> GROUP
                </h1>
              </div>
            </div>
          </div>
        </section>
        <Background number={2} />

        {/* <Background number={1} vw={"40vw"}/> */}
        <div id="games">
          <Gaming />
        </div>

        <div id="partners">
          <Partners />
        </div>
      </>
    );
  }
}
