import React, { useState } from "react";

export const Loader = () => {
  const [hide, sethide] = useState(null);
  setTimeout(
    function () {
      //Start the timer
      sethide(true); //After 1 second, set hide to true
    }.bind(this),
   500
  );

  return (
    <div id="loader" className={hide ? "hideLoader" : ""}>
      <div className="lds-hourglass"></div>
    </div>
  );
};
