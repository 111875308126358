import React from "react";
import { SectionHeading } from "../../components/sections/SectionHeading";
import "./home.css";
import {
  lotteryIcon,
  visionIcon,
  missionIcon
} from "../../components/library/Library";

export const WhatWeDo = () => {
  let title = "What We Do";
  let headingBody = (
    <>
    MOJAGROUP is a leading high-tech digital operator and consultancy service provider specialized in fintech,<br/> insurtech, gaming, mobile money, premium content and technology platforms with a geographical focus on Africa, Middle East and other regions.
    {/* MOJAGROUP is a leading gaming operator in Africa,<br/> whose services extend beyond the gaming industry to include financial technology, mobile money, and technology platforms. */}
    </>
  );
  return (
    <section id="agency">
      <div className="container">
        <SectionHeading title={title} body={headingBody} />
        <div className="row">
          <div className="col-lg-4">
            {/*single info block */}
            <div className="info text-center h-100">
              <div className="icon icon-circle other">
                {/* <i className="flaticon-paint-brush"></i> */}
                <img src={lotteryIcon} />
              </div>
              <h5 className="info-title">About Us</h5>
              <div className="description">
                <p className="description">
                  We have established joint ventures with leading mobile network operators, national lotteries, gaming commissions and media
                  companies.
                </p>
              </div>
            </div>
            {/*single info block ends */}
          </div>
          <div className="col-lg-4">
            {/*single info block */}
            <div className="info text-center h-100">
              <div className="icon icon-circle other">
                {/* <i className="flaticon-smartphone" aria-hidden="true"></i> */}
                <img src={visionIcon} />
              </div>
              <div className="description">
                <h5 className="info-title">Vision</h5>
                <p className="description">
                Our vision is to revolutionize the African space by offering innovative, user-friendly and digital solutions for its population.                </p>
              </div>
            </div>
            {/*single info block ends */}
          </div>
          <div className="col-lg-4">
            {/*single info block */}
            <div className="info text-center h-100">
              <div id="miss" className="icon icon-circle other">
                {/* <i className="flaticon-squares" aria-hidden="true"></i> */}
                <img src={missionIcon} style={{objectFit:"contain"}}  />
              </div>
              <div className="description">
                <h5 className="info-title">Mission</h5>
                <p className="description">
                To offer the most innovative and sought-after financial, content and entertainment products to users anywhere, anytime and on any device.                </p>
              </div>
            </div>
            {/*single info block ends */}
          </div>
        </div>
      </div>
    </section>
  );
};
