import React from "react";
import { SectionHeading } from "../../components/sections/SectionHeading";
import { TechnologyCard } from "../../components/sections/TechnologyCard";
import {
  omniIcon,
  analyticsIcon,
  innovationIcon,
  financial,
} from "../../components/library/Library";
import { Link, Redirect } from "react-router-dom";

export const Technology = () => {
  let title = "Technology";
  let headingBody =
    "We are committed to use and implement cutting-edge technology at all times to ensure the best experience for all of our clients.";

  let techCards = [
    {
      title: "Omni Channel",
      icon: omniIcon,
      body:
        "With over 10 years’ continuous development, MOJAGROUP’s platform has been fine tuned to be adapted to any market and to any governing technology.",
    },
    {
      title: "Data Analytics",
      icon: analyticsIcon,
      body:
        "Data analytics is the science of analyzing raw data to build conclusions on the investigated topic. Our technology allows for live monitoring and insights resulting in better user satisfaction.",
    },
    {
      title: "Data Innovation",
      icon: innovationIcon,
      body:
        "Data innovation offers an unprecedented opportunity to expand sources of evidence to inform product and strategy decisions.",
    },
  ];

  const to = (id) => ({
    pathname: "/services",
    state: {id},
    hash: "technology",
  });

  return (
    <section id="team" className="bg-light-gray">
      <div className="container">
        <SectionHeading title={title} body={headingBody} />
        <div className="services">
          <div className="row">
            {/*Box-1 */}
            {techCards.map((e, i) => {
              return (
                <TechnologyCard
                  key={i}
                  title={e.title}
                  icon={e.icon}
                  body={e.body}
                  cursor={true}
                  to={to(i)}
                />
              );
            })}

  
          </div>
        </div>

        <div className="row m-t-50 ">
          <div className="col-md-6 mt-5 mt-md-0 ">
            <div className="heading-two mb-5 ">
              <h2 className="headingExtra">Financial Technology</h2>
            </div>
            <br />
            <div className="textExtra">
              <p>
                Our financial technology mission is to work in partnership with
                clients to provide the vital link between businesses and
                customers via their preferred mobile channels, including SMS,
                USSD and web mobile. We provide technology solutions for the
                administration of clients’ sales services with real-time
                management for promotional activities.
              </p>

              <p>
                We manage complex multi-stakeholder, multi-country projects with
                rapid deployment and provision of the most effective solutions
                to match customers’ needs regardless of the device they may use.
                Our clients include the major mobile network operators and
                financial institutions throughout Africa.
              </p>

              <p>
                The payment industry is no longer supply-led or bank-centric.
                Customers expect a simplified and cheaper means for paying for
                goods and services via mobile and online channels. MOJAGROUP is at
                the forefront of fulfilling this demand for customers and
                thereby providing it
              </p>
            </div>
          </div>
          <div
            className="col-md-6 mt-5 mt-md-0  d-flex"
            style={{ justifyContent: "center" }}
          >
            <div style={{ width: "100%", height: "auto" }}>
              <img
                className="rightImage"
                src={financial}
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
