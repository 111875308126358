import React from "react";
import { SectionHeader } from "../../components/header/SectionHeader";
import { Background } from "../../components/sections/Background";
import { Loader } from "../../components/sections/Loader";

export const PrivacyPolicy = () => {
  return (
    <>
      <Loader />
      <SectionHeader fTitle="PRIVACY POLICY"  />
      <Background number={2} vw={"35vw"}/> 
      <Background number={3} vw={"30vw"}/> 
      <div className="container">
        <div className="row mt-5 pr-3 pl-3" style={{textAlign:"left"}}>
          <p>
            This Policy describes what to expect when we collect personal
            information. We are committed to ensuring your privacy and personal
            information are protected. For the purposes of this Privacy Policy,
            references to “we” or “us” shall refer to Moja Group.
          </p>
          <p>
            We shall take reasonable care to establish and maintain appropriate
            technical and organisational measures against unauthorised or
            unlawful processing of personal data and against accidental loss or
            destruction of, or damage to, personal data.
          </p>
          <p>
            We collect, use and share data primarily in the framework of our
            business, always in compliance with our duties as per the
            enforceable legislation. When we collect and use your personal
            information (including special category data), we ensure we look
            after it properly and use it in accordance with the privacy
            principles, keep it safe and will never sell it.
          </p>
          <p>
            We will only keep your personal information for as long as
            reasonably necessary to fulfil the relevant purposes of our business
            and in order to comply with our legal and regulatory obligations, in
            accordance with our Data Protection Policy.
          </p>
          <p>
            The time period we retain your personal information for will differ
            depending on the nature of the personal information and our
            regulatory obligations. We usually keep your information for a
            maximum of ten years from the end of our relationship with you.
            However, there may be circumstances in which we may be required to
            keep personal information for longer, for example, in case of
            dispute or legal action.
          </p>
          <p>We might share your personal information with:</p>
          <ul>
            <li>
            •	Moja Group affiliate and associate companies: for our general
              business administration, efficiency and accuracy purposes or for
              the prevention and detection of fraud.
            </li>
            <li>
            •	Third parties (outside our Group): limited to sharing of the
              information necessary for the proper execution of our business.
              This might include disclosure of your personal information to a
              third party which will only be made where the third party has
              agreed to keep your information strictly confidential and shall
              only be used for the specific purpose for which we provide it to
              them.
            </li>
          </ul>
          <p>
            We keep this notice under regular review. From time to time we may
            need to make changes to this Privacy Policy as a result, for
            example, of changes in the data protection regulations. You should
            check the Moja Group website periodically to view the most up to
            date Privacy Policy.
          </p>
          <p>
            The document that referred you to this Privacy Policy might contain
            personal information that Moja Group is processing. By providing
            your personal information or the personal information of someone
            included in such document, you acknowledge that we may use it only
            in the ways set out in this Privacy Policy.
          </p>
          <p>
            If you would like to ask for information about our Data Protection
            Policy, you can contact us at        <a href={"mailto:info@moja.co?"}>info@moja.co </a>
          </p>
        </div>
      </div>
    </>
  );
};
