import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <footer
        className="pb-2"
        style={{ zIndex: 0, height: 400 }}
        data-background="images/full-width-images/section-bg-contact.png"
      >
        <div className="container footer-components" >
          <div className="row m-0 ">
            {/* <div className="widget widget_contact text-center w-50"> */}

            {/* </div> */}
            <div className="col-md-12 col-sm-12">
              <div className="widget widget_contact text-center">
                <div className="wid_title h4">Follow Us</div>
                <div className="footer-social" style={{ zIndex: 3 }}>
                  <a
                    href="https://www.facebook.com/mojagroupinc/"
                    target="_target"
                    className="facebook-text-hvr "
                  >
                    <i className="fa fa-facebook " aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://twitter.com/IncMoja"
                    className="twitter-text-hvr "
                  >
                    <i className="fa fa-twitter " aria-hidden="true"></i>
                  </a>
                  {/* <a href="#."  target="_target" className="pinterest-text-hvr ">
                                    <i className="fa fa-pinterest-p " aria-hidden="true"></i>
                                </a> */}
                  <a
                    href="https://www.instagram.com/mojagroupinc/"
                    target="_target"
                    className="instagram-text-hvr "
                  >
                    <i className="fa fa-instagram " aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/moja-group-inc/"
                    target="_target"
                    className="instagram-text-hvr "
                  >
                    <i className="fa fa-linkedin " aria-hidden="true"></i>
                  </a>
                  {/* <a href="#."  target="_target" className="google-text-hvr ">
                                    <i className="fa fa-google-plus " aria-hidden="true"></i>
                                </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 mt-5" id="bottom-footer">
            <div className="col-lg-6 col-sm-12 text-lg-left text-center " id="copyrights">
              <p
                className="footer__copyrightText w-100"
                style={{ color: "white" }}
              >
                &copy; {new Date().getFullYear()} MojaGroup. All rights
                reserved.
              </p>
            </div>
            <div
            id="sections"
              className="col-lg-6  d-flex "
              //   style={{ alignItems: "center",justifyContent:"center" }}
            >
              {/* <div className="column"> */}
              <div className="column  w-100 " style={{ display: "flex" }}>
                <span className="w-100 text-center text-lg-right pr-4">
                  <Link style={{ color: "white" }} to="/disclaimer">
                    Disclaimer
                  </Link>
                  {/* <a style={{color:"red"}}>Disclaimer</a> */}
                </span>
                <span className="w-100 text-center text-lg-right pr-4">
                  <Link style={{ color: "white" }} to="/privacy_policy">
                    Privacy policy
                  </Link>
                  {/* <a style={{color:"red"}}>Privacy policy</a> */}
                </span>
                <span className="w-100 text-center text-lg-right">
                  <Link style={{ color: "white" }} to="/terms_and_conditions">
                    Terms & Conditions
                  </Link>
                  {/* <a style={{color:"red"}}>Terms & Conditions</a> */}
                </span>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <svg
          className="svg-abs svg-f-top-invert "
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1920 140"
          style={{
            marginBottom: "-9px",
            enableBackground: "new 0 0 1920 140",
            zIndex: -1,
          }}
          //  xml:space="preserve"
        >
          <path
            className="svg-white"
            d="M960,92.9C811.4,93.3,662.8,89.4,515.3,79c-138.6-9.8-277.1-26.2-409-53.3C97.8,24,0,6.5,0,0c0,0,0,140,0,140
                      l960-1.2l960,1.2c0,0,0-140,0-140c0,2.7-42.1,11.3-45.8,12.2c-45.1,11-91.5,20.1-138.4,28.1c-176.2,30.1-359.9,43.8-542.9,48.9
                      C1115.4,91.4,1037.7,92.7,960,92.9z"
          ></path>
        </svg>
      </footer>
    );
  }
}
