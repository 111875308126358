import React, { Component } from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import "./App.css";
import "./style.css";
import './jquery-jvectormap.css';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import AboutUsPage from "./containers/aboutUs/AboutUsPage";
import ServicesPage from "./containers/Services/ServicesPage";
import ContactUsPage from "./containers/contactUs/ContactUsPage";
// import Team from "./containers/team/Team";
// import Portfolio from "./containers/portfolio/Portfolio";
// import Testimonials from "./containers/testimonials/Testimonials";
// import Blog from "./containers/blog/Blog";
// import Counters from "./containers/counters/Counters";
// import BackgroundVideo from "./containers/backgroundVideo/BackgroundVideo";
// import Agency from "./containers/agency/Agency";
import HomePage from "./containers/home/HomePage";
// import Clients from "./containers/clients/Clients";
// import Price from "./containers/price/Price";
// import Video from "./containers/video/Video";
import PartnersPage from "./containers/partners/PartnersPage";
import CsrPage from "./containers/csr/CsrPage";
import SubsidiariesPage from "./containers/subsidiaries/SubsidiariesPage";
import ScrollToTop from "./components/header/ScrollToTop";
import { DisclaimerPage } from "./containers/disclaimer/DisclaimerPage";
import { PrivacyPolicy } from "./containers/privacyPolicy/PrivacyPolicy";
import { TermsAndConditions } from "./containers/termsAndConditions/TermsAndConditions";
import ReactGA from "react-ga";

class App extends Component {

  state={
    scroll:null
  }
  componentDidMount() {
    ReactGA.initialize("UA-187061746-1");
    ReactGA.pageview("/");
    // window.addEventListener("scroll", this.handleScroll);
  }


  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll=(event)=> {
    this.setState({ scroll: window.scrollY });
  }


  render() {
    return (
      <div className="App h-100">
        {/*Loader */}

        <div className="wrapper">
          <ScrollToTop>
            {/* start header */}
            <Header />
            {/*end header */}
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/about_us" component={AboutUsPage} />
              <Route exact path="/subsidiaries" component={SubsidiariesPage} />
              <Route exact path="/partners" component={PartnersPage} />
              <Route exact path="/csr" component={CsrPage} />
              <Route exact path="/services" component={ServicesPage} />
              <Route exact path="/contact_us" component={ContactUsPage} />
              <Route exact path="/disclaimer" component={DisclaimerPage} />
              <Route exact path="/privacy_policy" component={PrivacyPolicy} />
              <Route
                exact
                path="/terms_and_conditions"
                component={TermsAndConditions}
              />
              {/* <Route exact path="/" component={FinancialPage}/>
            <Route exact path="/" component={DisclaimerPage}/>
            <Route exact path="/" component={PrivacyPolicyPage}/>
            <Route exact path="/" component={TermsConditionspage}/> */}
              <Redirect to="/" />
            </Switch>
          </ScrollToTop>
          <Footer />

          {/*start scroll to top */}
          <a
            className={"scroll-top-arrow d-block"+(this.state.scroll > 150?"d-block":"")}
            onClick={() =>
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
            }
          >
            <i className="fa fa-angle-up "></i>
          </a>
          {/*end scroll to top  */}
        </div>
      </div>
    );
  }
}

export default App;
