import React from "react";

export const GameCard = ({ selected, icon, title, select,title1 }) => {
  let selectedColor =
    "invert(47%) sepia(82%) saturate(1883%) hue-rotate(160deg) brightness(93%) contrast(102%)";
  let defaultColor = "invert(1) ";
  return (
    <div className="gamecard col-md-4 mt-5 text-center grow" style={{cursor:"pointer"}} onClick={()=>select()} >
      <img
        style={{ filter: selected ? selectedColor : defaultColor }}
        src={icon}
      />
      <div className="overlay-info w-100 center">
        <h6 className="m-b-5" style={{color: selected ? "#00a4d6" : "black" }}>{title1?title1:title}</h6>
      </div>
    </div>
  );
};
