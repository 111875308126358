import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import "./contactUs.css";
import Popup from "reactjs-popup";
const { REACT_APP_API_ENDPOINT } = process.env;


const recaptchaRef = React.createRef();

export default class ContactUs extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    apiResponse: null,
    successRecaptcha: false,
    error: "",
    tryout: 0,
    loading: false,
  };


  handleFormSubmit = (e) => {
    const { name, email, message, successRecaptcha, tryout } = this.state;

    if (name != "" && email != "" && message != "") e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();

    // console.log(recaptchaRef);
    if (successRecaptcha) {
      if (name != "" && email != "" && message != "") {
        this.setState({ loading: true });
        axios({
          method: "POST",
          url: REACT_APP_API_ENDPOINT,
          data: {
            messageHtml: `<div> <p>Name: ${name} </p><p>Email: ${email} </p><p>Message: ${message}</p></div>`,
          },
        }).then((response) => {
          if (response.data.msg === "success") {
            this.setState({ apiResponse: "Email sent!" });

            this.resetForm();
          } else if (response.data.msg === "fail") {
            this.setState({
              apiResponse: "Oops, something went wrong. Try again",
            });
          }
        });
      } else
        this.setState({ error: "All fields are required", tryout: tryout + 1 });
    } else this.setState({ error: "Recaptcha needed", tryout: tryout + 1 });
  };

  handleChange = (e, field) => {
    let value = e.target.value;
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  setError = (msg) => {
    this.setState({ error: msg });
  };

  onChange = (value) => {
    this.setState({ successRecaptcha: true });
  };

  resetForm = () => {
    this.setState({
      name: "",
      email: "",
      message: "",
      successRecaptcha: false,
      tryout: 0,
      error: "",
      loading: false,
    });
  };

  render() {
    console.log(this.state);
    const { apiResponse, error, tryout, loading } = this.state;
    return (
        <section id="contact" className="contact">
          <div className="container ">
            {/* <div className="heading">
            <h2>
              <b>Contact Us</b>
            </h2>
          </div> */}

            <Popup open={apiResponse != null} modal nested>
              {(close) => (
                  <div className="modal-c">
                    <div className="content text-center">
                      <p>{apiResponse}</p>
                    </div>
                    <div className="actions">
                      <button
                          className="btn btn-md btn-block btn-gradient btn-rounded"
                          onClick={() => {
                            window.location.reload(false);
                            close();
                          }}
                      >
                        Done
                      </button>
                    </div>
                  </div>
              )}
            </Popup>
            <div className="row ">
              <div className="col-md-6">
                <div className="con-info contactText">
                  <div className="info-item d-flex">
                  <span>
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </span>
                    <div>
                      <p>C/o AXIS Fiduciary Ltd,</p>
                      <br />
                      <p> 2nd Floor, The AXIS 26 Cybercity,</p>
                      <br />
                      <p> Ebene 72201 Republic of Mauritius</p>
                    </div>
                  </div>
                  {/* <div className="info-item">
            
                  <span>
                    <i className="fa fa-phone" aria-hidden="true"></i>
                  </span>
              
                  <p>+1 023 456 789</p>
                </div> */}
                  <div className="info-item">
                  <span>
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </span>
                    <p>
                      <a href={"mailto:info@moja.co?"}>info@moja.co </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-form">
                  <div id="form-messages"></div>

                  <form className="needs-validation " action="#">
                    <div
                        className={`col-xs-6 ${
                            loading ? "elementToFadeInAndOut" : ""
                        }`}
                    >
                      <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Your Name"
                            id="name"
                            required
                            onChange={(e) => this.handleChange(e, "name")}
                        />
                      </div>
                    </div>
                    <div
                        className={`col-xs-6 ${
                            loading ? "elementToFadeInAndOut" : ""
                        }`}
                    >
                      <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Your Email"
                            id="email"
                            required
                            onChange={(e) => this.handleChange(e, "email")}
                        />
                      </div>
                    </div>

                    <div
                        className={`col-xs-12 ${
                            loading ? "elementToFadeInAndOut" : ""
                        }`}
                    >
                      <div className="form-group">
                      <textarea
                          name="message"
                          id="message"
                          className="form-control"
                          rows="8"
                          placeholder="Your Message"
                          id="message"
                          required
                          onChange={(e) => this.handleChange(e, "message")}
                      ></textarea>
                      </div>
                    </div>
                    <div
                        className={`col-xs-12 mb-2 ${
                            loading ? "elementToFadeInAndOut" : ""
                        }`}
                    >
                      <ReCAPTCHA
                          required
                          ref={recaptchaRef}
                          sitekey="6LdbYGcaAAAAAN7W8rYcXkNIUNBpg2JZOexiuRkm"
                          onChange={this.onChange}
                      />
                    </div>
                    <div
                        className="mt-2 mb-2 "
                        style={{ color: tryout % 2 == 0 ? "#bb0000" : "red" }}
                    >
                      <p>{error}</p>
                    </div>
                    <button
                        id="btnSubmit"
                        type="submit"
                        name="submit"
                        className="btn btn-lg btn-block btn-gradient btn-rounded"
                        onClick={(e) => this.handleFormSubmit(e)}
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}
