import React, { Component } from "react";
import { SectionHeader } from "../../components/header/SectionHeader";
import {
  casinoIcon,
  horseIcon,
  numberGamesIcon,
  scratchIcon,
  sportsIcon,
  lotteryIcon,
  partners,
  servicesHeader,
} from "../../components/library/Library";
import { SectionHeading } from "../../components/sections/SectionHeading";
import { GameCard } from "./GameCard";
import { Technology } from "./Technology";
import "./services.css";
import { RowMoreInfo } from "../../components/sections/RowMoreInfo";
import { Background } from "../../components/sections/Background";
import { Loader } from "../../components/sections/Loader";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

export default class ServicesPage extends Component {
  constructor(props) {
    super(props);

    let linkState = this.props.location.state;
    let hash = this.props.location.hash;

    this.state = {
      games: [
        {
          title: "Instant Lottery Games",
          title1:null,
          icon: lotteryIcon,
          body:
            "We offer a wide variety of Instant Lottery Games such as “Insta Lotto” or “Games of Chance” with advanced user interfaces and designs developed with best-in-class specialists.",
        },
        {
          title: "NUMBER GAMES (5/90-PICK 3-4/48)",
          title1: <> Number Games <br/> (5/90-pick 3-4/48)</>,
          icon: numberGamesIcon,
          body:
            "Our offering entails various games such as Lucky 3, Cash 5 and 5/90 where we strive for constant innovation and to develop new exciting games for our users.",
        },
        {
          title: "Scratch Cards",
          title1:null,
          icon: scratchIcon,
          body:
            "MOJAGROUP is happy to have also the digitized version of scratch games in their portfolio and to be able to offer this to our customers.",
        },
        {
          title: "Sports Betting",
          title1:null,
          icon: sportsIcon,
          body:
            "MOJAGROUP has an extensive sports betting footprint and can offer tailored solutions including Soccer Pool Betting in collaboration with best-in-class data and technology partners at all times.Horse Racing – We are the exclusive partner of the Jockey Club of Kenya and are offering related live betting products across our markets.",
        },
        {
          title: "Horse Racing",
          title1:null,
          icon: horseIcon,
          body:
            "We are the exclusive partner of the Jockey Club of Kenya and are offering related live betting products across our markets.",
        },
        {
          title: "Casino Games",
          title1:null,
          icon: casinoIcon,
          body:
            "Our casino games entail popular games such as Blackjack, Roulette but also Monopoly live and much more!",
        },
      ],
      selectedGameIndex: linkState
        ? hash == "#servicesgames"
          ? linkState.id
          : 0
        : 0,
      selectedTechnologyIndex: linkState
        ? hash == "#technology"
          ? linkState.id
          : 0
        : 0,
      showSpinner: false,
    };
  }

  componentDidMount() {
    const { hash } = this.props.location;
    ReactGA.pageview("/services");
    // scroll to id
    if (hash != "") {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }

  select = (index) => {
    this.setState({ selectedGameIndex: index, showSpinner: true });
    setTimeout(() => {
      this.setState({ showSpinner: false });
    }, 1000);
  };
  render() {
    const {
      games,
      selectedGameIndex,
      showSpinner,
      selectedTechnologyIndex,
    } = this.state;

    // let gamingBody = (
    //   <>
    //     <p>
    // We provide a complete solution to operate lotteries fully through our
    // secure mobile and online gaming platforms, games of chance, sports
    // betting, horse racing, and online casino in emerging markets.
    //     </p>
    //     <p>
    // Our internal “Lotto Studio” enables us to develop and customize our
    // propriety owned games to the different markets we operate in.
    //     </p>
    //     <p>
    // Add to that our integration with leading content / third-party
    // providers allows us to offer a wide range of games on our platform.
    //     </p>
    //   </>
    // );

    let gamingBody = <>    We provide a complete solution to operate lotteries fully through our
    secure mobile and online gaming platforms, games of chance, sports
    betting, horse racing, and online casino in emerging markets. 
      <br />
        Our internal “Lotto Studio” enables us to develop and customize our
    propriety owned games to the different markets we operate in.
      <br />
    Add to that our integration with leading content / third-party
    providers allows us to offer a wide range of games on our platform.</>;

    return (
      <>
        <Loader />
        <SectionHeader fTitle={"SERVICES"} img={servicesHeader} />
        <Background number={1} vw={"100vw"} />

        <Technology s={selectedTechnologyIndex} />

        <Background number={2} />
        <div className="container mt-5">
          <SectionHeading title={"GAMING & LOTTERIES"} body={gamingBody} />
          <div className="row " id="servicesgames">
            {games.map((e, i) => {
              return (
                <GameCard
                  key={i}
                  title={e.title}
                  title1={e.title1}
                  icon={e.icon}
                  selected={selectedGameIndex == i}
                  select={() => this.select(i)}
                />
              );
            })}
          </div>

          <RowMoreInfo
            title={games[selectedGameIndex].title}
            body={games[selectedGameIndex].body}
            spinner={showSpinner}
          />
        </div>
      </>
    );
  }
}
