import React, { Component } from "react";
import "./subsidiaries.css";
import { SectionHeader } from "../../components/header/SectionHeader";
import { SectionHeading } from "../../components/sections/SectionHeading";
import {
  acajouLogo,
  drcMap,
  ghanaMap,
  ivoryCoastMap,
  keedNlaLogo,
  kenyaMap,
  lonaciLogo,
  map,
  mojabetCdLogo,
  mojabetKeLogo,
  mojabetUgLogo,
  nlaLogo,
  senegalMap,
  subsidaries, tanzaniaMap,
  mojabetTzLogo,
  ugandaMap,
} from "../../components/library/Library";
import { Background } from "../../components/sections/Background";
import { Loader } from "../../components/sections/Loader";
import { Spinner } from "../../components/Spinner";
import ReactGA from "react-ga";

export default class SubsidiariesPage extends Component {
  state = {
    countries: [
      {
        title: "KEED Ghana",
        body:
          "Keed is the exclusive digital partner for the National Lottery Authority (NLA) in Ghana, with a unique value proposition and a large gaming portfolio.",
        img: ghanaMap,
        icon: keedNlaLogo,
        height: 90,
      },
      {
        title: "RF20 Uganda",
        body:
          "RF20 is an independent gaming operator licensed by the Gaming Board of Uganda.",

        img: ugandaMap,
        icon: mojabetUgLogo,
        height: 100,
      },
      {
        title: "Terra Moneta DRC",
        body:
          "Among the very few digitally-focused companies in the DRC, Terra Moneta is a SONAL licensed gaming operator.",
        img: drcMap,
        icon: mojabetCdLogo,
        height: 100,
      },
      {
        title: "Farasi Mbili Kenya",
        body:
          "In partnership with the Jockey Club of Kenya, Farasi Mbili brings a new and innovative digital experience to horse racing and football pool betting.",
        img: kenyaMap,
        icon: mojabetKeLogo,
        height: 100,
      },
      {
        title: "Acacia Senegal",
        body:
          "Acacia is a digital partner for the Loterie National de Senegal (LONASE), bringing its tech expertise and innovative platform to offer a wide range of lottery and sports betting games.",
        img: senegalMap,
        icon: acajouLogo,
        height: 60,
      },
      {
        title: "Gtechnologies Ivory Coast",
        body:
          "Gtechnologies is the digital partner for LONACI in Ivory Coast, working closely with them to digitalize existing games and launch new ones.",
        img: ivoryCoastMap,
        icon: lonaciLogo,
        height: 50,
      },
      {
        title: "Lucky Games Tanzania",
        body:
            "Mojabet.tz is the exclusive digital partner for the National Lottery Authority (NLA) in Tanzania with a unique value proposition and bouquet of games.",
        img: tanzaniaMap,
        icon: mojabetTzLogo,
        height: 150,
      },
    ],
    selected: 0,
    hovered: null,
    showSpinner: false,
  };

  componentDidMount() {
    ReactGA.pageview("/subsidiaries");
  }

  render() {
    const { countries, selected, hovered, showSpinner } = this.state;
    let sectionBody =
      "MOJAGROUP is currently operating six entities in Sub-Saharan Africa with different set-ups and strong local partnerships.";

    let active = "white";
    let notActive = "#142d47";

    return (
      <>
        <Loader />
        <SectionHeader fTitle="SUBSIDIARIES" img={subsidaries} />
        <Background number={2} />
        <div className="mt-5" />
        <SectionHeading body={sectionBody} />

        <div className="container  ">
          <div className="row">
            {countries.map((e, i) => {
              return (
                <div
                  key={i}
                  className="col-md-4 country border border-secondary border-1"
                  style={{
                    backgroundColor:
                      hovered == i
                        ? active
                        : selected == i
                        ? active
                        : notActive,
                    cursor: "pointer",
                    color:
                      hovered == i
                        ? notActive
                        : selected == i
                        ? notActive
                        : active,
                  }}
                  onClick={() => {
                    this.setState({ selected: i, showSpinner: true });
                    setTimeout(() => {
                      this.setState({ showSpinner: false });
                    }, 1000);
                  }}
                  onMouseEnter={() => this.setState({ hovered: i })}
                  onMouseLeave={() => this.setState({ hovered: null })}
                >
                  {e.title}
                </div>
              );
            })}
          </div>
        </div>
        <div className="container  mt-5 h-50">
          <div className="row ">
            {showSpinner ? (
              <div
                className="col-md-12 d-flex"
                style={{ justifyContent: "center", alignItems: "center",objectFit:"contain", height:100 }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <div className="col-md-6 " id="blockContainer">
                  <p className="country-text text-left">{countries[selected].body}</p>
                  <img
                    className="country-icon"
                    src={countries[selected].icon}
                    style={{ height: countries[selected].height }}
                  />
                </div>
                <div className="col-md-6 country-img">
                  <img src={countries[selected].img} className="img-fluid" />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
