import React, { Component } from "react";
import { SectionHeader } from "../../components/header/SectionHeader";
import {
  employees,
  engagement,
  ethics,
  society,
  csrHeader
} from "../../components/library/Library";
import { Background } from "../../components/sections/Background";
import { Loader } from "../../components/sections/Loader";
import { SectionHeading } from "../../components/sections/SectionHeading";
import { CsrRowCard } from "./CsrRowCard";
  import ReactGA from "react-ga";
  
export default class CsrPage extends Component {
  state = {
    csr: [
      {
        title: "Employees & People",
        body:
          "we want to protect and support our team members for successful careers. We want to provide opportunities and resources to our co-workers and aspire to lead by example in health and wellness, diversity and inclusion, and support personal development. We are committed to equal employment opportunity and the protection of human rights.",
        img: employees,
      },
      {
        title: "Ethics",
        body:
          "Moja Group commits to doing the right thing. We are always acting in a legal, ethical, and trustworthy manner and following our regulatory obligations while complying with all rules and regulations.",
        img: ethics,
      },
      {
        title: "Engagement",
        body:
          "We are convinced that it takes all of us working together to have an impact. MOJAGROUP partners with organizations in the communities where we operate and work to improve lives and society as a whole. ",
        img: engagement,
      },
      {
        title: "Society & Culture ",
        body:
          "We deeply believe in the power of different societies and cultures. MOJAGROUP strives to primarily support the community in a holistic manner where we think that education and health are some of the most important contributing factors. Simultaneously, we feel that culture has to be protected; this can range from cultural monuments to the arts.",
        img: society,
      },
    ],
  };


  componentDidMount(){
    ReactGA.pageview('/csr');
}


  render() {
    const { csr } = this.state;
    let scope = "Scope";
    let scopeBody = (
      <>
        <p>
          Our proven gaming technology enables us to work as a trusted National
          Lottery partner with Governments across Africa.
        </p>
        <p>
          On an exclusive and non-exclusive basis, we offer games of chance,
          casinos, sports betting and horse racing through market-leading
          mobile, online and digital platforms.
        </p>
        <p>
          &#x2752; <strong>Current Countries:</strong>
        </p>
        Uganda
        <br />
        DRC
        <br />
        Ghana
        <br />
        Kenya
        <br />
        Ivory Coast
        <br />
        Senegal
      </>
    );
    return (
      <>
      <Loader />
      
        <SectionHeader csr={window.innerWidth>774? true:false} img={csrHeader} fTitle={"Corporate Social Responsibility"} />

        <div className="container" style={{marginTop:"3%"}}>
          <SectionHeading
            title={"CSR"}
            body={
              "Moja’s Corporate Social Responsibility approach is built around four areas, Employees & People, Ethics, Engagement and Society & Culture ."
            }
          />
        <Background number={1} vw={"80vw"}/>

          {csr.map((e, i) => {
            return (
              <div key={i} >
                {i == csr.length - 2 ? (
              <Background number={2}/>
                ) : (
                  <></>
                )}
                <CsrRowCard
                  key={i}
                  textLeft={i % 2 == 0}
                  textTitle={e.title}
                  textBody={e.body}
                  img={e.img}
                />
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
