import React from "react";
import { Link } from "react-router-dom";

export const TechnologyCard = (props) => {
  const { icon, title, body, select, whiteBack, height, cursor, to } = props;
  const ContentTag = to ? Link : "div";

  return (
    <ContentTag
      to={to}
      className={"col-md-6 col-lg-4 mt-3  " + (body ? "equal" : "h-100")}
      onClick={select}
    >
      <div
        className={`box-service align-items-center text-center techcard mb-2 grow  ${
          whiteBack == true ? "" : " back"
        }`}
        style={{ cursor: cursor ? "pointer" : "inherit" }}
      >
        {/* <span className="fa fa-mobile icon-2"></span> */}
        <img src={icon} style={{ height }} />
        <div className="content-service text-center">
          <h5> {title}</h5>
          {body ? <p>{body}</p> : <></>}
        </div>
      </div>
    </ContentTag>
  );
};
