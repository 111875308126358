import React from "react";
import { SectionHeader } from "../../components/header/SectionHeader";
import { Background } from "../../components/sections/Background";
import { Loader } from "../../components/sections/Loader";

export const TermsAndConditions = () => {
  return (
    <>
      <Loader />
      <SectionHeader fTitle="TERMS & CONDITIONS" />
      <Background number={2} vw={"45vw"}/> 
      <Background number={3} vw={"40vw"}/> 
      <div className="container  ">
        <div className="row mt-5 pr-3 pl-3" style={{ textAlign: "left" }}>
          <p>
            Please read these terms and conditions carefully. By accessing this
            website and any of its pages, you agree to be bound by these terms
            and conditions.
          </p>
          <p>
            This website may contain links to websites operated by third
            parties. Such links are provided for your convenience only. We do
            not control such websites and are not responsible for their
            contents. Inclusion of links to such websites does not imply any
            endorsement of the material on them or any association with their
            operators.
          </p>
          <p>
            <strong>
              <b>TERMS OF ACCESS</b>
            </strong>
          </p>
          <p>
            Moja Group makes no representations or warranties of any kind
            whether express or implied with respect to this website or its
            content (including any text, graphics, advertisements, links or
            other items) in respect of accuracy, completeness or otherwise and
            excludes all such representations and warranties to the fullest
            extent permitted by law. You acknowledge that you are solely
            responsible for the use to which you put the website and all
            information you obtain from it. Moja Group will not be liable for
            any loss or damage arising directly or indirectly from your use of
            this website.
          </p>
          <p>
            <strong>
              <b>VARIATION OF CONTENT</b>
            </strong>
          </p>
          <p>
            Moja Group reserves the right, in its absolute discretion at any
            time and without notice, to remove, amend or vary any of the content
            which appears on any page of this website, including any of these
            terms and conditions. Moja Group endeavours to ensure that
            information displayed on this website is accurate, but there is a
            time delay between changes in information and the reflection of such
            changes on this website. Information contained in this website may
            be therefore out of date or inaccurate.
          </p>
          <p>
            <strong>
              <b>USE OF CONTENT</b>
            </strong>
          </p>
          <p>
            You may store, manipulate, analyse, reformat, print and display the
            content of this website solely for your own personal use. You are
            not permitted to publish, re-transmit, re-distribute or otherwise
            re-produce any of the content which appears on any page of this site
            in any format to anyone and you are prohibited from using any such
            content in connection with any business or commercial enterprise.
            The information contained in this website is not an invitation to
            invest in Moja Group, nor does it constitute investment advice of
            any kind. No service or product described on this website is
            guaranteed to be available in the form described or at all.
          </p>
          <p>
            <strong>
              <b>INTELLECTUAL PROPERTY</b>
            </strong>
          </p>
          <p>
            All design, text, graphics and the selection or arrangement thereof
            in the pages of this website and the screens displaying the pages of
            this website are the copyright of Moja Group unless otherwise
            indicated. All trademarks, service marks, company names or logos and
            copyrights used in this site are the property of their respective
            holders. No permission is given by Moja Group or by any other
            copyright holder in respect of the use of any such trademarks,
            service marks, company names or logos or copyrights and such use may
            constitute an infringement of the holder’s rights.
          </p>
          <p>
            <strong>
              <b>INVALIDITY</b>
            </strong>
          </p>
          <p>
            If any part of these terms and conditions is unenforceable
            (including any provision in which Moja Group excludes its liability
            to you) the enforceability of all other parts of these terms and
            conditions will not be affected.
          </p>
          <p>
            <strong>
              <b>GOVERNING LAW</b>
            </strong>
          </p>
          <p>
            These terms and conditions shall be governed by and interpreted in
            accordance with English law and the English courts shall have
            jurisdiction to resolve any disputes arising from them or from your
            use of this website.
          </p>
        </div>
      </div>
    </>
  );
};
