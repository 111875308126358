import React from "react";

export const SectionHeading = ({ title, body }) => {
  return (
    <div className="heading mg-3">
      {title ? (
        <h2>
          <b>{title}</b>
        </h2>
      ) : (
        <></>
      )}
      <p className="sub-heading">{body}</p>
    </div>
  );
};
