import React from "react";
import { bg1, bg2, bg3 } from "../library/Library";

export const Background = ({ number,vw }) => {
  let body = <></>;

  if (number == 2 ) {
    body = (
      <div className="backImageLeft">
        <img
          src={bg2}
          style={{
            height: vw?vw:"100%",
            width: "auto",
            left: 0,
            position: "absolute",
            zIndex: 0,
            opacity:0.4
          }}
        />
      </div>
    );
  } else if (number == 1 || number==3) {
    body = (
      <div className="backImageRight">
        <img
          src={number==1?bg1:bg3}
          style={{
            height: vw?vw:"100%",
            width: "auto",
            right: 0,
            position: "absolute",
            zIndex: 0,
            opacity:0.4,
          }}
        />
      </div>
    );
  }
  return body;
};
