import React from "react";
import { partnersObj } from "../../components/library/Library";
import { SectionHeading } from "../../components/sections/SectionHeading";

export const PartnersLargeView = () => {
  let headingbody =
    "MOJAGROUP is working with some of the best local and international companies in their segment to ensure the best service delivery at all times.";

  return (
    <section className="clients bg-white">
      <div className="container ">
        <SectionHeading body={headingbody} />

        <div className="row w-100 d-inline-flex justify-content-center">
          {partnersObj.map((e, i) => {
            return (
              <div
                key={i}
                className={
                  "d-inline-flex centering col-sm-4 " +
                  ((i+1)%2 ==0 ? "col-lg-3" : "col-lg-2 ")
                }
                style={{ justifyContent: "center" }}
              >
                <a href={e.url} target="_target" className="client item grow">
                  <img src={e.icon} alt={e.alt} style={{ width: e.width }} />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
