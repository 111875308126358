import React from "react";
import Loader from "react-loader-spinner";

export const Spinner = () => {
  return (
    <Loader
      type="TailSpin"
      color="#00BFFF"
      height={50}
      width={50}
      timeout={1000} //1 secs
    />
  );
};
