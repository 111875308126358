import React from "react";
import { SectionHeader } from "../../components/header/SectionHeader";
import { Background } from "../../components/sections/Background";
import { Loader } from "../../components/sections/Loader";

export const DisclaimerPage = () => {
  return (
    <>
      <Loader />
      <SectionHeader fTitle="DISCLAIMER" />
      <Background number={2} vw={"20vw"}/> 
      <Background number={1} vw={"30vw"}/> 
      <div className="container  pr-3 pl-3">
        <div className="row mt-5 pr-3 pl-3"  style={{textAlign:"left"}}>
          <p>
            The information contained in this site has been published by Moja
            Group and is being made available only to users on the basis set
            forth in our terms and conditions of use.
          </p>
          <p>
            The information contained on this site, which does not purport to be
            comprehensive or constitute an offer or invitation or form the basis
            of any contract in respect of the provisions of services by Moja
            Group, has not been independently verified. While the information
            has been prepared in good faith, no representation, warranty,
            assurance or undertaking (express or implied) is or will be made,
            and no responsibility or liability is or will be accepted by Moja
            Group or by any of its respective officers, employees or agents in
            relation to the adequacy, accuracy, completeness or reasonableness
            of the information. All and any such responsibility and liability is
            expressly disclaimed. In particular, but without prejudice to the
            generality of the foregoing, no representation, warranty, assurance
            or undertaking is given as to the achievement or reasonableness of
            any future projections, services, management estimates, prospects or
            returns contained on this site.
          </p>
          <p>
            This site has been provided to users for information only and on the
            express understanding that they shall use it only for information
            purposes. Moja Group gives no undertaking to provide users with
            access to any additional information or to update this site or any
            additional information, or to correct any inaccuracies in it which
            may become apparent.
          </p>
        </div>
      </div>
    </>
  );
};
