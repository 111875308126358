// -----------------------Icons----------------------------
const lotteryIcon = "assets/icons/Instant Lottery Icon.png";
const numberGamesIcon = "assets/icons/Number Games Icon.png";
const scratchIcon = "assets/icons/Scratch Icon.png";
const sportsIcon = "assets/icons/Sports Betting Icon.png";
const horseIcon = "assets/icons/Horse Racing Icon.png";
const casinoIcon = "assets/icons/Casino Games icon.png";
const omniIcon = "assets/icons/Omni Channel Icon.png";
const analyticsIcon = "assets/icons/Data Analytics Icon.png";
const innovationIcon = "assets/icons/Data Innovation Icon.png";
const visionIcon = "assets/icons/Vision Icon.png";
const missionIcon = "assets/icons/Mission Icon.png";

// -----------------------Logo----------------------------
const digitainLogo = "assets/icons/Digitain.png";
const evolutionLogo = "assets/icons/evolution-gaming-logo.png";
const phumelelaLogo = "assets/icons/phumelela-gaming.png";
const jockeyLogo = "assets/icons/the-jockey-club-vector-logo.png";
const nlaLogo = "assets/icons/NLA.png";
const lonaseLogo = "assets/icons/Lonase.png";
const lonaciLogo = "assets/icons/Logo Lonaci.png";
const moobitekLogo = "assets/icons/Moobitek logo.png";
const moobifunLogo = "assets/icons/moobifun logo.png";
const mojaLogo = "assets/MojaWhiteLogo.png";
const acajouLogo = "assets/icons/acajou logo.png";
const mojabetUgLogo = "assets/icons/mojabet-ug.png";
const mojabetKeLogo = "assets/icons/mojabet-ke.png";
const mojabetCdLogo = "assets/icons/mojabet-cd.png";
const keedNlaLogo = "assets/icons/keed-nla.png";
const airtelUgLogo = "assets/icons/Airtel_Uganda-Logo.png";
const vodacomLogo = "assets/icons/Vodacom-Logo.png";
const mtnGroupLogo = "assets/icons/MTN_Group-Logo.png";
const safaricomLogo = "assets/icons/safaricom logo.png";
const freeLogo = "assets/icons/free logo.png";
const orangeLogo = "assets/icons/orange logo.png";
const  vodafoneLogo= "assets/icons/1200px-Vodafone_2017_logo.png";
const  airtelLogo= "assets/icons/airtel tigo.png";
const  moovLogo= "assets/icons/Moov-CI.png";
const  mojabetTzLogo= "assets/icons/mojabet-tz.png";

// -----------------------Maps----------------------------
const map = "assets/icons/Map.png";
const drcMap = "assets/maps/DRC.png";
const ghanaMap = "assets/maps/Ghana.png";
const ivoryCoastMap = "assets/maps/IVORY COAST.png";
const kenyaMap = "assets/maps/Kenya.png";
const senegalMap = "assets/maps/Senegal.png";
const ugandaMap = "assets/maps/Uganda.png";
const tanzaniaMap = "assets/maps/Tanzania.png";

// -----------------------Images----------------------------
const tempo = "assets/tempo.PNG";
const employees = "assets/images/PHOTO-2020-12-28-17-31-36.jpg";
const ethics = "assets/images/PHOTO-2020-12-28-17-31-31.jpg";
const engagement = "assets/images/PHOTO-2020-12-28-17-31-30.jpg";
const society = "assets/images/PHOTO-2020-12-28-17-31-34.jpg";
const financial = "assets/images/Financial-Tech.jpg";
const together = "assets/images/About-Us.jpg";
const casino = "assets/images/Casino-Games.jpg";
const horseRacing = "assets/images/Horse-Racing.jpg";
const instantLotto = "assets/images/Insta-Lotto-Img.jpg";
const games = "assets/images/Lottery-Games-Img.jpg";
const scratch = "assets/images/Scratch-Img.jpg";
const sportsBetting = "assets/images/Sports-Betting-Img.jpg";

// -----------------------backgrounds----------------------------
const home = "assets/images/HomeHeader.jpg";
const about = "assets/images/AboutHeader.jpg";
const partners = "assets/images/Partners-&-Affiliates-Header.jpg";
const subsidaries = "assets/images/SubsidiariesHeader.jpg";
const servicesHeader = "assets/images/ServicesHeader.jpg";
const csrHeader = "assets/images/csrHeader.jpg";
const extra = "assets/extra.jpg";
const mojaGroup = "assets/images/Parallux.jpg";
const mojaGroupCut = "assets/images/Parallux-cut.jpg";
const mojaGroupAll = "assets/images/Parallux-all.jpg";
const paralluxN = "assets/images/Parallux-n.jpg";
const bg1 = "assets/bg-nod-1.png";
const bg2 = "assets/bg-nod-2.png";
const bg3 = "assets/bg-nod-3.png";

const partnersObj = [
  {
    alt: "Digitain logo",
    icon: digitainLogo,
    width: 140,
    url: "https://www.digitain.com",
  },
  {
    alt: "Lonase logo",
    icon: lonaseLogo,
    width: 100,
    url: "https://www.lonase.sn",
  },
  {
    alt: "Phumelela logo",
    icon: phumelelaLogo,
    width: 150,
    url: "http://phumelela.com",
  },
  {
    alt: "Jockey logo",
    icon: jockeyLogo,
    width: 150,
    url: "https://www.thejockeyclub.co.uk",
  },
  {
    alt: "Nla logo",
    icon: nlaLogo,
    width: 135,
    url: "https://www.keed-nla.com/",
  },
  {
    alt: "Lonaci logo",
    icon: lonaciLogo,
    width: 150,
    url: "https://lonaci.net",
  },
  {
    alt: "Moobitek logo",
    icon: moobitekLogo,
    width: 180,
    url: "www.moobitek.com",
  },
  {
    alt: "Moobifun logo",
    icon: moobifunLogo,
    width: 180,
    url: "https://www.moobifun.com",
  },
  {
    alt: "Evolution logo",
    icon: evolutionLogo,
    width: 180,
    url: "https://www.evolution.com",
  },
  { alt: "MTN", icon: mtnGroupLogo, width: 150, url: "https://www.mtn.com" },
  {
    alt: "Airtel",
    icon: airtelUgLogo,
    width: 150,
    url: "https://www.airtel.com",
  },
  {
    alt: "Vodacom",
    icon: vodacomLogo,
    width: 170,
    url: "https://www.vodacom.com",
  },
  // {
  //   alt: "Halotel",
  //   icon: evolutionLogo,
  //   width: 180,
  //   url: "https://halotel.co.tz",
  // },
  // {
  //   alt: "Tigo",
  //   icon: evolutionLogo,
  //   width: 180,
  //   url: "https://www.tigo.co.tz",
  // },
  {
    alt: "Free",
    icon: freeLogo,
    width: 140,
    url: "https://www.free.sn",
  },
  {
    alt: "Safaricom",
    icon: safaricomLogo,
    width: 180,
    url: "https://www.safaricom.co.ke",
  },
  {
    alt: "Orange",
    icon: orangeLogo,
    width: 90,
    url: "https://www.orange.com",
  },
  {
    alt: "AIRTELTIGO",
    icon: airtelLogo,
    width: 140,
    url: "https://www.airteltigo.com.gh/home",
  },
  {
    alt: "VODAFONE",
    icon: vodafoneLogo,
    width: 180,
    url: "https://vodafone.com.gh/home/",
  },
  {
    alt: "MOOV",
    icon: moovLogo,
    width: 90,
    url: "https://www.moov-africa.ci/",
  },
];



export {
  missionIcon,
  mojaGroupCut,
  mojaLogo,
  visionIcon,
  csrHeader,
  financial,
  tempo,
  innovationIcon,
  lotteryIcon,
  numberGamesIcon,
  scratchIcon,
  sportsIcon,
  horseIcon,
  casinoIcon,
  digitainLogo,
  evolutionLogo,
  phumelelaLogo,
  jockeyLogo,
  nlaLogo,
  lonaseLogo,
  lonaciLogo,
  moobitekLogo,
  moobifunLogo,
  map,
  bg1,
  bg2,
  bg3,
  omniIcon,
  analyticsIcon,
  home,
  about,
  partners,
  subsidaries,
  extra,
  employees,
  ethics,
  engagement,
  society,
  together,
  casino,
  horseRacing,
  instantLotto,
  games,
  scratch,
  sportsBetting,
  servicesHeader,
  mojaGroup,
  drcMap,
  ghanaMap,
  ivoryCoastMap,
  kenyaMap,
  senegalMap,
  ugandaMap,
  acajouLogo,
  mojabetUgLogo,
  mojabetKeLogo,
  mojabetCdLogo,
  keedNlaLogo,
  mojaGroupAll,
  partnersObj,
  airtelUgLogo,
  vodacomLogo,
  mtnGroupLogo,
  safaricomLogo,
  freeLogo,
  orangeLogo,
  vodafoneLogo,
  airtelLogo,
  moovLogo,
  paralluxN,
  tanzaniaMap,
  mojabetTzLogo
};
