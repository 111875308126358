import React, { useState } from "react";
import { Link } from "react-router-dom";

export const GamingCard = ({ img, icon, title, id , height}) => {
  const to = {
    pathname: "/services",
    state: { id },
    hash: "servicesgames",
  };
  const [opacity, setOpacity] = useState(0.8);
  return (
    <div
      className="col-md-4 col-sm-6 col-12 items graphic"
      onMouseEnter={() => setOpacity(0.9)}
      onMouseLeave={() => setOpacity(0.8)}
    >
      <div className="item-img" style={{objectFit:"contain"}}>
        <img
          src={img}
          alt={title}
          style={{ objectFit: "contain", margin: 0 }}
        />
        <div className="item-img-overlay " style={{ opacity: opacity }}>
          <Link to={to}>
            {/* <i className="fa fa-camera portfolio-icon"></i> */}
            <img style={{height,width:"auto"}}src={icon} />
          </Link>
          <div className="overlay-info width-100 text-center">
            <Link to={to}>
              <h6 className="m-b-5">{title}</h6>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
