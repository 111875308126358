import React from "react";

export const ImageTextRow = ({ textLeft, textTitle, textBody, img }) => {
  const TLeft = () => {
    return (
      <div className="col-md-6 mt-5 mt-md-0 ">
        {textTitle ? (
          <div className="heading-two mb-5 ">
            <h2 className="headingExtra">{textTitle}</h2>
          </div>
        ) : (
          <></>
        )}
        <br />
        <div className="textExtra">{textBody}</div>
      </div>
    );
  };

  const IRight = () => {
    return (
      <div
        className="col-md-6 mt-5 mt-md-0  d-flex"
        style={{ justifyContent: "center" }}
      >
        <div style={{ width: "100%", height: "auto" }}>
          <img
            className="rightImage"
            src={img}
            style={{ objectFit: "contain" }}
          />
        </div>
      </div>
    );
  };
  let left = textLeft ? TLeft() : IRight();
  let right = !textLeft ? TLeft() : IRight();
  return (
    <section id="team" className={!textLeft ? "bg-light-gray" : ""}>
      <div className="container">
        <div className="row ">
          {left}
          {right}
        </div>
      </div>
    </section>
  );
};
