import React from "react";

export const CsrRowCard = ({ textLeft, textTitle, textBody, img }) => {
  const TLeft = () => {
    return (
      <div className="col-md-6 mt-5 mt-md-0 " style={{flexDirection:"column",justifyContent:"center",display:"flex"}}>
        <div className="heading-two mb-2 ">
          <h2 className="headingExtra">{textTitle}</h2>
        </div>
        <br />
        <div className="textExtra">{textBody}</div>
      </div>
    );
  };

  const IRight = () => {
    return (
      <div className="col-md-6 mt-5 mt-md-0">
        <div className="text-center w-100" >
          <img className="rightImage img-fluid " src={img} style={{objectFit:"contain",width:"100%", height:"auto"}} />
        </div>
      </div>
    );
  };
  let left = textLeft ? TLeft() : IRight();
  let right = !textLeft ? TLeft() : IRight();

  return (
    <div className="row" style={{display:"block",marginLeft:3}}>
      <div className="row m-t-50 w-100 text-center">
        {left}
        {right}
      </div>
    </div>
  );
};
