import React from "react";
import { SectionHeading } from "../../components/sections/SectionHeading";
import { GamingCard } from "./GamingCard";
import {
  casinoIcon,
  horseIcon,
  lotteryIcon,
  numberGamesIcon,
  scratchIcon,
  sportsIcon,
  tempo,
  instantLotto,
  games,
  scratch,
  sportsBetting,
  horseRacing,
  casino,
} from "../../components/library/Library";

export const Gaming = () => {
  let title = "Gaming & Lotteries";
  let headingBody = "Through our secure mobile and online gaming platforms, we provide a complete solution to fully operate lotteries, games of chance, sports betting, horse racing and online casino in emerging markets. Our internal “Lotto Studio” enables us to develop and customize our propriety owned games to the different markets we operate in. Add to that our integration with leading content / third party providers which allows us to offer a wide-range of games on our platform.";

  let gamesCardDetails = [
    {
      title: "Instant Lottery Games",
      id: 0,
      icon: lotteryIcon,
      img: instantLotto,
      height: 100,
    },
    {
      title: "Number Games ",
      id: 1,
      icon: numberGamesIcon,
      img: games,
      height: 60,
    },
    {
      title: "Scratch Cards",
      id: 2,
      icon: scratchIcon,
      img: scratch,
      height: 80,
    },
    {
      title: "Sports Betting",
      id: 3,
      icon: sportsIcon,
      img: sportsBetting,
      height: 100,
    },
    {
      title: "Horse Racing",
      id: 4,
      icon: horseIcon,
      img: horseRacing,
      height: 100,
    },
    {
      title: "Casino Games",
      id: 5,
      icon: casinoIcon,
      img: casino,
      height: 80,
    },
  ];
  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <SectionHeading title={title} body={headingBody} />

        <div className="gallery row">
          {gamesCardDetails.map((e, i) => {
            return (
              <GamingCard
                key={i}
                title={e.title}
                id={e.id}
                icon={e.icon}
                img={e.img}
                height={e.height}
              />
            );
          })}

          <div className="clear-fix"></div>
        </div>
      </div>
    </section>
  );
};
