import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
    
  // {/* </React.StrictMode> */}
  ,
  document.getElementById("root")
);

serviceWorker.unregister();
