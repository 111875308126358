import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logoWhite, mojaLogo } from "../library/Library";
import { Animated } from "react-animated-css";

import "./header.css";

export default class Header extends Component {
  state = {
    show: false,
    scroll: null,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll=(event)=> {
    this.setState({ scroll: window.scrollY });
  }

  switch = (show) => {
    this.setState({ show });
  };

  render() {
    let sections = (
      <ul className="navbar-nav ml-auto ">
        {/* start menu item */}

        <li className="nav-item active dropdown ">
          <Link
            to="/"
            onClick={() => this.switch(false)}
            href="#home"
            className="nav-link scroll"
          >
            Home
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/about_us"
            onClick={() => this.switch(false)}
            href="#about"
            className="nav-link scroll"
          >
            About Us
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/subsidiaries"
            onClick={() => this.switch(false)}
            href="#contact"
            className="nav-link scroll"
          >
            Subsidiaries
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/partners"
            onClick={() => this.switch(false)}
            href="#services"
            className="nav-link scroll"
          >
            Partners & Affiliates
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/services"
            onClick={() => this.switch(false)}
            href="#services"
            className="nav-link scroll"
          >
            Services
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/csr"
            onClick={() => this.switch(false)}
            href="#services"
            className="nav-link scroll"
          >
            CSR
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/contact_us"
            onClick={() => this.switch(false)}
            href="#services"
            className="nav-link scroll"
          >
            Contact us
          </Link>
        </li>
      </ul>
    );
    return (
      <header className={this.state.scroll > 70 ? "sticky header-appear" : ""}>
        {/* start navigation  */}
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top pt-0 pb-0">
          <div className="container p-relative">
            <a className="navbar-brand logo grow" href="/" title="Logo">
              <img src={mojaLogo} className="img-fluid " alt="MOJA" />
            </a>

            <div
              className={
                "collapse navbar-collapse " + (this.state.show ? "show" : " ")
              }
              id="navbarNav"
            >
              {window.innerWidth < 991 ? (
                <Animated
                  animationIn="fadeIn"
                  animationOut="fadeOut"
                  isVisible={this.state.show}
                >
                  {sections}
                </Animated>
              ) : (
                <>{sections}</>
              )}
            </div>

            <button
              className="navbar-toggler"
              type="button"
              onClick={() => this.switch(!this.state.show)}
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </button>
          </div>
        </nav>
      </header>
    );
  }
}
