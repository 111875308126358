import React, { Component } from "react";
import { SectionHeader } from "../../components/header/SectionHeader";
import { together, map, about } from "../../components/library/Library";
import { Background } from "../../components/sections/Background";
import { ImageTextRow } from "../../components/sections/ImageTextRow";
import { Loader } from "../../components/sections/Loader";
import ReactGA from "react-ga";

export default class AboutUsPage extends Component {
  componentDidMount() {
    ReactGA.pageview("/about_us");
  }
  render() {
    let whatwedoTitle = "What We Do";
    let whatwedoBody = (
      <>
        <p>
          MOJAGROUP is a leading high-tech digital operator and consultancy
          service provider specialized in fintech, insurtech, gaming, mobile
          money, premium content and technology platforms with a geographical
          focus on Africa, Middle East and other regions.
        </p>

        <p>
          We have established joint ventures with leading mobile network
          operators, national lotteries, gaming commissions, media companies and
          governing entities.
        </p>

        <p>
          It is our goal to provide customers with products and services, many
          of which have been previously unavailable, by the simplest and fastest
          channels. We want to broaden the access of financial, telecoms and
          gaming products and services throughout Africa.
        </p>
      </>
    );

    let scope = "Scope";
    let scopeBody = (
      <>
        <p>
          Our proven gaming technology enables us to work as a trusted National
          Lottery partner with Governments across Africa.
        </p>
        <p>
          On an exclusive and non-exclusive basis, we offer games of chance,
          casinos, sports betting and horse racing through market-leading
          mobile, online and digital platforms.
        </p>
        <p>
          &#x2752; <strong>Current Countries:</strong>
        </p>
        Uganda
        <br />
        DRC
        <br />
        Ghana
        <br />
        Kenya
        <br />
        Ivory Coast
        <br />
        Senegal
        <br/>
        Tanzania
      </>
    );
    return (
      <>
        <Loader />
        <SectionHeader fTitle="ABOUT" sTitle="US" img={about} />
        <Background number={1} vw={"50vw"} />
        <ImageTextRow
          textLeft={true}
          textTitle={whatwedoTitle}
          textBody={whatwedoBody}
          img={together}
        />

        <ImageTextRow
          textLeft={false}
          textTitle={scope}
          textBody={scopeBody}
          img={map}
        />
      </>
    );
  }
}
