import React from "react";
// import useScript from "../../hooks/useScript";
import "./header.css";

export const SectionHeader = (props) => {
  // this script is reimported to reload the images
  // useScript("js/main.js");

  const { fTitle, sTitle, desc, img, csr } = props;
  return (
    <div
      className="king-full-image-area bg-img bg-gradient parallax"
      id="home"
      // data-background="images/full-width-images/section-bg-home.jpg"
      style={{ display: "flex", backgroundImage:`url(${img})` }}

    >
      <div className="bg-overlay transparent-dark bg-gradient"></div>
      <div className="king-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="home-contain text-white text-center">
                <p
                  className=" d-flex "
                  style={{
                    fontSize: "2.5em",
                    lineHeight: "normal",
                    justifyContent: "center",
                  }}
                >
                  {csr ? (
                    <>
                      <strong>
                        <b>C</b>
                      </strong>
                      orporate &nbsp; <br/>
                      <strong>
                        <b>S</b>
                      </strong>
                      ocial &nbsp; 
                      <strong>
                        <b>R</b>
                      </strong>
                      esponsibility
                    </>
                  ) : (
                    <>
                      <strong>
                        <b>{fTitle}</b>
                      </strong>
                      {sTitle}
                    </>
                  )}
                </p>
                {desc ? <h4 className=" font-weight-light">{desc}</h4> : <></>}
                {/* <div className="text-center">
                                    <a href="https://player.vimeo.com/video/142874198"
                                        className="video-play-button video-link">
                                        <i className="fa fa-play" aria-hidden="true"></i>
                                    </a>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="scroll-down hidden-xs">
                <a href="#agency" className="icon-scroll scroll"></a>
            </div> */}
      <svg
        className="svg-abs svg-f-btm"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1920 140"
        style={{
          marginBottom: "-9px",
          enableBackground: "new 0 0 1920 140",
          zIndex: 0,
        }}

        // xml:space="preserve"
      >
        <path
          className="svg-white"
          d="M960,92.9C811.4,93.3,662.8,89.4,515.3,79c-138.6-9.8-277.1-26.2-409-53.3C97.8,24,0,6.5,0,0c0,0,0,140,0,140
                      l960-1.2l960,1.2c0,0,0-140,0-140c0,2.7-42.1,11.3-45.8,12.2c-45.1,11-91.5,20.1-138.4,28.1c-176.2,30.1-359.9,43.8-542.9,48.9
                      C1115.4,91.4,1037.7,92.7,960,92.9z"
        ></path>
      </svg>
    </div>
  );
};
