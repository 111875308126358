import React from "react";
import { partnersObj } from "../../components/library/Library";
import { SectionHeading } from "../../components/sections/SectionHeading";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

export const Partners = () => {
  let title = "Partners & Affiliates";
  let headingbody =
    "MOJAGROUP is working with some of the best local and international companies in their segment to ensure the best service delivery at all times.";

  return (
    <section id="agency" className="clients bg-light-gray">
      <div className="container ">
        <SectionHeading title={title} body={headingbody} />
        <div className="row ">
          <OwlCarousel
            className="owl-carousel owl-theme owl-client"
            loop
            margin={10}
            autoplay={true}
            responsive={{
              0: {
                items: 1,
              },
              767: {
                items: 2,
              },
              1000: {
                items: 3,
              },
            }}
          >
            {partnersObj.map((e, i) => {
              return (
                <a
                  href={e.url}
                  target="_target"
                  key={i}
                  className="item-patners item "
                  style={{ width: e.width }}
                >
                  <img src={e.icon} alt={e.alt} style={{ width: e.width }} />
                </a>
              );
            })}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};
