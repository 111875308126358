import React from "react";
import { Spinner } from "../Spinner";
export const RowMoreInfo = ({ title, body, spinner,m }) => {
  return (
    <div className={`row  ${m?"":"m-t-50"}`}>
      <div className="col-md-12 mt-3 mt-md-0 ">
        {spinner ? (
          <div
                className="col-md-12  d-flex"
                style={{ justifyContent: "center", alignItems: "center", height:100 }}
              >     <Spinner /></div>
     
        ) : (
          <>
            <div className="heading-two mb-5 ">
              <h2 className="headingExtra">{title}</h2>
            </div>
            <br />
            <div className="textExtra">
              <p>{body}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
