import React, { useState } from "react";
import { SectionHeading } from "../../components/sections/SectionHeading";
import { TechnologyCard } from "../../components/sections/TechnologyCard";
import {
  omniIcon,
  analyticsIcon,
  innovationIcon,
  financial,
} from "../../components/library/Library";
import { RowMoreInfo } from "../../components/sections/RowMoreInfo";

export const Technology = ({ s }) => {
  const cards = [
    {
      title: "Omni Channel",
      icon: omniIcon,
      body: (
        <>
          With over 10 years’ continuous development, MOJA’s platform has been
          fine tuned to be adapted to any market and to any governing technology
          (web, mobile or USSD).
          <br /> Our ease of integration with any Telecom or Content Provider
          has positioned us a step ahead of the competition. We are integrated
          with Africa’s biggest Telecom providers, allowing us to enter any new
          market within a very short time-frame.
        </>
      ),
    },
    {
      title: "Data Analytics",
      icon: analyticsIcon,
      body: (
        <>
          Data analytics is the science of analyzing raw data to build
          conclusions on the investigated topic.
          <br />
            MOJAGROUP Group can provide deep data analytics at every stage and is proud to
          define itself as a data-driven company using quantitative insights to
          improve its products and those of its clients.
        </>
      ),
    },
    {
      title: "Data Innovation",
      icon: innovationIcon,
      body: (
        <>
          Data innovation offers an unprecedented opportunity to expand sources
          of evidence to inform product and strategy decisions.
          <br />
          Moja Group is proud that we are spearheading efforts to use data to make
          smart decisions to build innovative and leading gaming technology
          solutions.
        </>
      ),
    },
  ];
console.log(s)
  const [selected, select] = useState(s ? s : 0);
  const [spinner, setSpinner] = useState(false);

  let title = "Technology";
  let headingBody =
    "We are committed to use and implement cutting-edge technology at all times to ensure the best experience for all of our clients.";

  return (
    <section id="team" className="bg-light-gray">
      <div className="container">
        <SectionHeading title={title} body={headingBody} />
        <div className="services">
          <div className="row">
            {cards.map((e, i) => {
              return (
                <TechnologyCard
                  cursor={true}
                  key={i}
                  title={e.title}
                  icon={e.icon}
                  select={() => {
                    setSpinner(true);
                    setTimeout(() => {
                      setSpinner(false);
                    }, 1000);
                    select(i);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div id="technology" className="pt-5">
          <RowMoreInfo
            title={cards[selected].title}
            body={cards[selected].body}
            spinner={spinner}
            m={true} //if there is margin in here
          />
        </div>

        <div className="row m-t-50 ">
          <div className="col-md-6 mt-3 mt-md-0 ">
            <div className="heading-two mb-5 ">
              <h2 className="headingExtra">Financial Technology</h2>
            </div>
            <br />
            <div className="textExtra">
              <p>
                Our financial technology mission is to work in partnership with
                clients to provide the vital link between businesses and
                customers via their preferred mobile channels, including SMS,
                USSD and web mobile. We provide technology solutions for the
                administration of clients’ sales services with real-time
                management for promotional activities.
              </p>

              <p>
                We manage complex multi-stakeholder, multi-country projects with
                rapid deployment and provision of the most effective solutions
                to match customers’ needs regardless of the device they may use.
                Our clients include the major mobile network operators and
                financial institutions throughout Africa.
              </p>

              <p>
                The payment industry is no longer supply-led or bank-centric.
                Customers expect a simplified and cheaper means for paying for
                goods and services via mobile and online channels. MOJAGROUP is at
                the forefront of fulfilling this demand for customers and
                thereby providing it
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-5 mt-md-0 px-md-5">
            <div>
              <img className="rightImage" src={financial} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
