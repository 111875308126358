import React from "react";
import { SectionHeading } from "../../components/sections/SectionHeading";
import { TechnologyCard } from "../../components/sections/TechnologyCard";
import {
  omniIcon,
  analyticsIcon,
  innovationIcon,
  financial,
  keedNlaLogo,
  mojabetUgLogo,
  mojabetCdLogo,
  mojabetKeLogo,
  acajouLogo,
  lonaciLogo,
    mojabetTzLogo,
} from "../../components/library/Library";

export const CurrentSubsidiaris = () => {
  let title = "Our Current Subsidiaries";
  let headingBody = "MOJAGROUP is currently operating six entities in Sub-Saharan Africa with different set-ups and strong local partnerships.";

  let subsidiaries = [
    {
      height:100,
      title: "Farasi Mbili Kenya",
      icon: mojabetKeLogo,
      body:
        "In partnership with the Jockey Club of Kenya, Farasi Mbili brings a new and innovative digital experience to horse racing and football pool betting.",
    },

    {  height:100,
      title: "RF20 Uganda",
      icon: mojabetUgLogo,
      body:
        "RF20 is an independent gaming operator licensed by the Gaming Board of Uganda",
    },
    {  height:100,
      title: "Terra Moneta DRC",
      icon: mojabetCdLogo,
      body:
        "Among the very few digitally-focused companies in the DRC, Terra Moneta is a SONAL licensed gaming operator",
    },
    {
      title: "KEED Ghana",
      icon: keedNlaLogo,
      body:
        "Keed is the exclusive digital partner for the National Lottery Authority (NLA) in Ghana, with a unique value proposition and a large gaming portfolio",
    },
    {
      title: "Acacia Senegal",
      icon: acajouLogo,
      body:
        "Acacia is a digital partner for the Loterie National de Senegal (LONASE), bringing its tech expertise and innovative platform to offer a wide range of lottery and sports betting games",
    },
    {
      title: "Gtechnologies Ivory Coast",
      icon: lonaciLogo,
      body:
        "Gtechnologies is the digital partner for LONACI in Ivory Coast, working closely with them to digitalize existing games and launch new ones   ",
    },
    {  height:100,
      title: "Mojabet Tanzania",
      icon: mojabetTzLogo,
      body:
          "Mojabet.tz is the exclusive digital partner for the National Lottery Authority (NLA) in Tanzania with a unique value proposition and bouquet of games.",
    },
  ];
  return (
    <div className="container mb-3" >
      <SectionHeading title={title} body={headingBody} />
      <div className="services">
        <div className="row">
          {subsidiaries.map((e, i) => {
            return (
              <TechnologyCard
                key={i}
                title={e.title}
                icon={e.icon}
                body={e.body}
                whiteBack={true}
                height={e.height}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
