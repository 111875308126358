import React, { Component } from "react";
import { SectionHeader } from "../../components/header/SectionHeader";
import { SectionHeading } from "../../components/sections/SectionHeading";
import { PartnersLargeView } from "./PartnersLargeView";
import "./partners.css";
import { partners } from "../../components/library/Library";
import { Background } from "../../components/sections/Background";
import { Loader } from "../../components/sections/Loader";
import ReactGA from "react-ga";
export default class PartnersPage extends Component {
  
  componentDidMount(){
    ReactGA.pageview('/partners');
}

  render() {
    return (
      <div>
        <Loader />
        <SectionHeader fTitle="PARTNERS & AFFILIATES" img={partners} />

        <Background number={1} />
        <PartnersLargeView />
      </div>
    );
  }
}
