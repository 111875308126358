import React, { Component } from "react";
import { SectionHeader } from "../../components/header/SectionHeader";
import { subsidaries } from "../../components/library/Library";
import { Loader } from "../../components/sections/Loader";
import ContactUs from "./ContactUs";
import ReactGA from "react-ga";

export default class ContactUsPage extends Component {
  
  componentDidMount(){
    ReactGA.pageview('/contact_us');
}

  render() {
    return (
      <>
          <Loader />
        <SectionHeader fTitle="CONTACT" sTitle="US" img={subsidaries} />
        <ContactUs />
       
      </>
    );
  }
}
